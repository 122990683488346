<template lang="pug">
     v-container
        template(v-if="productItems && productItems.length > 0")
            v-card(v-for="product in productItems" :key="product.id" class="mx-auto mb-1" max-width="600")
                v-card-text.px-1.py-0
                    v-row.my-0
                        v-col.barcode(cols="1" class="pr-0")
                            v-icon(color="green" v-if="product.hasAction && product.hasReportData") mdi-bookmark-check
                            v-icon(color="#ff4081" v-else-if="product.hasAction") mdi-bookmark
                            v-icon(color="#ff4081" @click.native="showBarcodeDialog(product)") mdi-information-outline 
                        v-col.py-1 {{ product.name }} 
                            div
                                b {{ product.barcode }}
                        v-col(cols="auto")
                            v-btn#likecb(x-small dark outlined color="#808080" @click.native="changePresence(product)")
                                template(v-if="product.reportData && product.reportData.presense")
                                    v-icon(v-if="product.reportData.presense == 'V'") mdi-check
                                    v-icon(v-if="product.reportData.presense == 'OOS'") mdi-minus
                        v-col(cols="auto")
                            v-btn(x-small fab dark color="blue" @click.native="showDialog(product)")
                                v-icon mdi-plus
        template(v-else-if="productItems && productItems.length == 0")
            v-card(class="mx-auto mb-1" max-width="600")
                v-card-text
                    v-icon(color="red") mdi-alert
                    span Нет продуктов в выбранной категории

        v-card(v-if="loading" class="mx-auto" flat max-width="50")
            v-progress-circular(:size="50" color="primary" indeterminate )

        v-card(v-if="error" class="mx-auto" max-width="600")
            v-card-title Что-то пошло не по плану
                v-spacer
                v-icon(color="red") mdi-alert
            v-card-subtitle {{ error }}

        v-snackbar(v-model="snackbar.open" :timeout="snackbar.timeout" centered :color="snackbar.type") {{ snackbar.text }}
            template(v-slot:action="{ attrs }")
                v-btn(text v-bind="attrs" @click="snackbar.open = false") Close

        v-dialog(v-if="selectedItem" v-model="dialog" max-width="600" persistent fullscreen transition="dialog-bottom-transition")
            v-card
                v-toolbar(dark color="deep-purple accent-4")
                    v-btn(icon dark @click="hideDialog()")
                        v-icon mdi-close
                    v-toolbar-title {{ selectedItem.name }}

                template(v-if="productError && !productLoading")
                    v-card-title Что-то пошло не по плану
                        v-spacer
                        v-icon(color="red") mdi-alert
                    v-card-subtitle {{ productError }}

                v-card-text
                    div.w50(v-if="productLoading" class="mx-auto")
                        v-progress-circular(:size="50" color="primary" indeterminate)
                    v-form(v-on:submit.prevent="submit" ref="form" lazy-validation v-model="valid")
                        template(v-for="field in selectedItemReport.reportFieldWithData")
                            template(v-if="field.fieldType.name == 'STRING'")
                                v-text-field(v-model="field.value" :label="field.name" :rules="validateField(field)" required)
                                    template(v-if="selectedItemReport.tradeAction" v-slot:append-outer)
                                        v-icon(color="yellow" :title="'Поле акции: ' + selectedItemReport.tradeAction.name") mdi-star

                            template(v-else-if="field.fieldType.name == 'INT'")
                                VNumber(v-model="field.value" :label="field.name" :rules="validateField(field)" required @input="changeInput(field)")
                                    template(v-if="selectedItemReport.tradeAction" v-slot:append-outer)
                                        v-icon(color="yellow" :title="'Поле акции: ' + selectedItemReport.tradeAction.name") mdi-star

                            template(v-else-if="field.fieldType.name == 'FLOAT'")
                                v-text-field(v-model="field.value" :label="field.name" :rules="validateField(field)" required)
                                    template(v-if="selectedItemReport.tradeAction" v-slot:append-outer)
                                        v-icon(color="yellow" :title="'Поле акции: ' + selectedItemReport.tradeAction.name") mdi-star

                            template(v-else-if="field.fieldType.name == 'SELECT'")
                                v-select(v-if="field.name != 'ДМП размер' && field.name!='Акционный ценник на полке'" 
                                         v-model="field.value" 
                                         :label="field.name" 
                                         :items="field.options" 
                                         item-text="value" 
                                         item-value="value" 
                                         :rules="validateField(field)" 
                                         required 
                                         :disabled="dis"
                                         @change="changeSelect(field)")
                                v-select(v-else-if="field.name == 'ДМП размер'" :disabled="dis" v-model="field.value" :label="field.name" :items="field.options" item-text="value" item-value="value" required :rules='validateDmp(field)')
                                    template(v-if="selectedItemReport.tradeAction" v-slot:append-outer)
                                        v-icon(color="yellow" :title="'Поле акции: ' + selectedItemReport.tradeAction.name") mdi-star
                                v-select(v-else-if="field.name == 'Акционный ценник на полке'" v-model="field.value" :label="field.name" :items="field.options" item-text="value" :rules="validateField(field)" required item-value="value")

                            template(v-if="field.name === 'ДМП' && field.value === 'Да'")
                                v-divider
                                v-file-input(v-model="dmpPhotos" accept="image/*" label="Загрузите фото ДМП")
                                Photo(:loadphotos="dmpPhotos"
                                    :photos="selectedItemReport.photo ? selectedItemReport.photo.filter(x => x.dmp) : []"
                                    :tmpphotos="selectedItemDMPPhotos"
                                    :isDmp="true"
                                    @loading="isLoadDMPPhoto = true"
                                    @result="dmpfchange"
                                    @remove="removeItemImage"
                                    @removeTmp="removeDMPTmp")

                        v-divider
                        v-file-input(v-model="photos" multiple accept="image/*" label="Загрузите фото ТСД или ТОВАРА с ценником")
                        Photo(:loadphotos="photos"
                            :photos="selectedItemReport.photo ? selectedItemReport.photo.filter(x => !x.dmp) : []"
                            :tmpphotos="selectedItemRPhotos"
                            @loading="isLoadPhoto = true"
                            @result="fchange"
                            @remove="removeItemImage"
                            @removeTmp="removeItemTmp")

                        v-divider
                        v-text-field(v-model="selectedItemReport.comment" label="Комментарий")

                        v-card-actions
                            v-btn(type="submit" :disabled="isLoadPhoto || loadingData") {{ loadingData ? 'Ожидание...' : 'Сохранить' }}
                            v-btn(@click="hideDialog()") Отмена
        v-dialog.dialog-barcode( v-if="selectedItem" v-model="barcodeDialog" width="auto")
          div.barcode
            vue-barcode(:value="selectedItem.barcode")
        template(v-if="productItems && productItems.length > 0")
            div.emptyspace

            v-btn(fixed bottom left fab color="#ff4081" @click="sheet = !sheet" title="Прикрепить фото")
                v-icon(color="#fff") mdi-camera

            v-bottom-sheet(v-model="sheet" persistent)
                v-sheet.overflow-auto(height="250px")
                    v-container
                        Photo(:loadphotos="prereport.photos"
                            :photos="prereport.reportPhoto"
                            :tmpphotos="prereport.tempPhotos"
                            @result="repFileChange"
                            @loading="isLoadMainPhoto = true"
                            @remove="removeImage"
                            @removeTmp="removeTmp")

                        div.comment
                            v-text-field(v-model="prereport.comment" label="Комментарий к отчёту")
                        v-row(align="center" justify="space-around")
                            label#repfileslable(for="repfiles" class="v-btn v-btn--contained v-btn--fab v-btn--round theme--light v-size--default")
                                v-icon(color="#fff") mdi-camera
                            v-file-input#repfiles(v-model="prereport.photos" multiple accept="image/*")
                            v-btn.button( color="#ff4081" :loading="prereport.sending"  @click="sendPrereport()" class="white--text" :disabled="isLoadMainPhoto || uploadedAllReports ") Сохранить
                            v-btn(fab color="#ff4081" @click="sheet = !sheet")
                                v-icon(color="#fff") mdi-arrow-down

            v-btn(fixed bottom right :color="report && report.reportStatus.value ? 'green' : '#ff4081'" class="white--text" title="Отправить отчёт" @click="sendPrereport()" :disabled="isLoadMainPhoto || uploadedAllReports")
                v-icon(v-if="report && report.reportStatus.value" color="white" class="mr-1") mdi-check
                span Отправить отчёт

</template>


<script>
import { config } from '@/_helpers'
import { categoryService, productService, reportService } from '@/_services'
// import { fileService } from '@/_services'
import VueBarcode from '@chenfengyuan/vue-barcode';
import { mapState, mapMutations } from "vuex";
import VNumber from "./VNumber"
import Photo from "./Photo"

export default {
  props: [ 'id', 'nid', 'pid', 'cid' ],
  components: {
      VNumber,
      Photo,
      VueBarcode
  },
  data() {
    return {
        loading: false,
        error: null,
        dialog: false,
        sheet: false,
        loadingData: false,
        
        isLoadPhoto: false,
        isLoadDMPPhoto: false,
        isLoadMainPhoto: false,

        count_actions: 0,
        productItems: null,

        selectedItem: null,
        currentproduct: null, 
        selectedItemReport: {},
        selectedItemRPhotos: [],
        selectedItemDMPPhotos: [],

        report: null,

        prereport: {
            tradePointId: null,
            comment: '',
            reportPhoto: [],
            tempPhotos: [],
            photos: [],
            sending: false
        },

        productLoading: false,
        productError: null,

        valid: false,

        photos: [],
        dmpPhotos: [],

        snackbar: {
            open: false,
            timeout: 2000,
            text: '',
            type: 'success'
        },
        dis:false,
        disabledSendReport: null,
        actionProduct: null,
        barcodeDialog: false

    };
  },

  mounted() {
    this.prereport.projectId = this.id
    this.prereport.tradePointId = this.pid
    this.loadReport()
    if (this.cid == 'a') this.setSelectedCategory({ id: 'a', name: 'Акция', type: 'action' })
    if (!this.selectedCategory) {
      this.loadCategory()
    }
    else {
      this.setAppBarTitle(this.selectedCategory.name)
    }
    this.disabledSendReport = false
    this.loadProducts()
    this.countActions()
  },

  destroyed() {
    this.setSelectedCategory(null)
  },
  

  computed: {
    ...mapState(['appBarTitle']),
    ...mapState("category", ["selectedCategory"]),

    apiPath() {
      return config.apiUrl
    },
    
    changeDisabled(){
      return false
    },

    uploadedAllReports() {
      this.disabledButtonSendReport()
      return this.disabledSendReport
    },
    
  },
  methods: {
    ...mapMutations(['setAppBarTitle']),
    ...mapMutations("category", ["setSelectedCategory"]),


    loadCategory() {
      this.setAppBarTitle('loading')
      categoryService.loadCategory(this.cid)
          .then(data => {
              this.setSelectedCategory(data)
              this.setAppBarTitle(data.name)
          })
          .catch(error => {
              this.setSelectedCategory(null)
              this.setAppBarTitle(error)
          })
    },

    disabledButtonSendReport(){
      // if(this.productItems.some(product => product.hasAction == true)) {
      //   let productWithActions = this.productItems.filter(product => product.hasAction == true)
      //   if(productWithActions.some(product => product.hasReportData == false)) {
          this.disabledSendReport = true
      //   }else {
      //     this.disabledSendReport = false
      //   }
      // }
      if(this.actionProduct){
        if(this.actionProduct.some(product => product.hasReportData == false)){
          this.disabledSendReport = true
        } else {
          this.disabledSendReport = false
        }
      }
      
    },

    showBarcodeDialog(product){ 
      this.barcodeDialog = true
      this.selectedItem = product
    },

    loadReport() {
      reportService.getReportByPointId(this.pid)
          .then(data => {
              this.report = data
              if (this.report) {
                  this.prereport.photos = []
                  this.prereport.tempPhotos = []
                  this.prereport.comment = data.comment
                  this.prereport.reportPhoto = data.reportPhoto
              }
          })
          .catch(error => {})
    },

    countActions() {
      productService.countPointActionProducts(this.id, this.pid).then(data => {
          this.count_actions = data;
      })
    },

    changeInput(field) {
      if (field.name === 'Остаток' && field.value === '0') {
          this.selectedItemReport.reportFieldWithData.forEach(x => {
              if (x.fieldType.value === 5 && x.name != 'Акционный ценник на полке') {
                  x.value = x.defValue;
                  this.dis = true
              }
          });
      } else {
        this.dis = false
      }

    },

    changeSelect(field) {
      if (field.name === 'ДМП' && field.value === 'Нет') {
          this.selectedItemReport.reportFieldWithData.forEach(x => {
              if (x.name == 'ДМП размер') {
                  x.value = x.defValue;
              }
          });
      }
    },

    loadProducts() {
      this.loading = true
      productService.loadPointActionProducts(this.id, this.pid)
            .then(data => {
                this.actionProduct = data  
            })
            .catch(error => {
                this.error = error
            })
      if (this.cid == 'a') {
        productService.loadPointActionProducts(this.id, this.pid)
            .then(data => {
                this.productItems = data
                this.loading = false
            })
            .catch(error => {
                this.error = error
                this.loading = false
            })
      } else {
        productService.loadPointProducts(this.id, this.pid, this.cid)
            .then(data => {
                this.productItems = data
                this.loading = false
            })
            .catch(error => {
                this.error = error
                this.loading = false
            })
      }
    },

    changePresence(product) {
      let presence = null
      let pd = product.reportData
      if (!pd || !pd.presense || pd.presense == 'OUT') presence = 'V'
      else if (pd.presense == 'V') presence = 'OOS'
      else if (pd.presense == 'OOS') presence = 'OUT'

      this.$set(product, 'reportData', {})
      product.reportData.presense = presence

      let data = {
              projectId: this.id,
              tradePointId: this.pid,
              productId: product.id,
              presense: presence
          }
      if (!product.hasAction){
        reportService.sendReportData(data)
          .then(d => {
              product.reportData = d
              if (!this.report) this.loadReport()
          })
          .catch(error => {
              // product.reportData.presense = null
          })
      }
            
    },

    showDialog(product) {
        this.dis = false
        this.selectedItem = null
        this.selectedItemReport = {}
        this.selectedItemRPhotos = []
        this.selectedItemDMPPhotos = []
        this.selectedItem = product
        this.dialog = true
        this.productLoading = true
        this.productError = null
        productService.getProductReportData(this.id, this.pid, product.id)
          .then(data => {
              if(!product.hasReportData){
                data.reportFieldWithData.map(field => { 
                  if(field.name == 'Остаток' && field.value == 0){
                    this.dis = true
                  }
                    if(field.fieldType.value == 5 && field.name != 'Акционный ценник на полке'){
                      field.value = 'Нет'
                      field.defValue = 'Нет'
                    }
                    if(field.name == 'ДМП размер'){
                      field.value = '-'
                      field.defValue = '-'
                      field.options.map(opt => {
                        if(opt.value == "иное"){
                          opt.value = '-'
                        }
                      })
                    }
                  })
                  
              }else {
                data.reportFieldWithData.map(field => {
                  if(field.name == 'Остаток' && field.value == 0){
                    this.dis = true
                  }
                  if(field.fieldType.value == 5 && field.name != 'Акционный ценник на полке'){
                    field.defValue = 'Нет'
                  }
                  if(field.name == 'ДМП размер'){
                      field.defValue = '-'
                      field.options.map(opt => {
                        if(opt.value == "иное"){
                          opt.value = '-'
                        }
                      })
                  }
                })
              }

              this.currentproduct = product
              this.productLoading = false
              this.selectedItemReport = data
            })
          .catch(error => {
              this.productLoading = false
              this.productError = error
          })
    },

    hideDialog() {
      this.selectedItem = null
      this.selectedItemReport = {}
      this.selectedItemRPhotos = []
      this.selectedItemDMPPhotos = []
      this.selectedItem = null
      this.dialog = false
      this.productLoading = false
      this.productError = null
    },

    sendPrereport() {
      if (this.isLoadMainPhoto) return;
      if (this.count_actions < 0) {
        this.openSnackbar('Нельзя сохранить. Не все промо отчёты заполнены', 'error');
        return;
      }

      this.prereport.sending = true
      reportService.sendPreReport(this.prereport)
        .then(data => {
            this.report = data
            this.prereport.photos = []
            this.prereport.tempPhotos = []
            this.prereport.comment = data.comment
            this.prereport.reportPhoto = data.reportPhoto
            this.sheet = false
            this.prereport.sending = false
            this.openSnackbar('Отчёт отправлен', 'success')
        })
        .catch(error => {
            this.prereport.sending = false
            this.openSnackbar('При отправке отчёта произошла ошибка', 'error')
        })
      this.prereport.reportPhoto = []
      },

    removeImage(image) {
      reportService.deletePhoto(this.report.uuid, image.uuid).then(() => {
          let list = this.prereport.reportPhoto
          let i = list.findIndex(el => el.uuid == image.uuid)
          if (i != -1) {
              list.splice(i, 1)
          }
      })
    },

    removeItemImage(image) {
      reportService.deleteDataPhoto(this.report.uuid, image.uuid, this.currentproduct.id).then(() => {
        let list = this.selectedItemReport.photo
        let i = list.findIndex(el => el.uuid == image.uuid)
        if (i != -1) {
          list.splice(i, 1)
        }
      })
    },

    repFileChange(data) {
      this.isLoadMainPhoto = false;
      this.prereport.photos = []
      this.prereport.tempPhotos = data
    },

    removeItemTmp(i) {
      this.selectedItemRPhotos.splice(i, 1)
    },

    removeDMPTmp(i) {
      this.selectedItemDMPPhotos.splice(i, 1)
    },

    removeTmp(i) {
      this.prereport.tempPhotos.splice(i, 1)
    },

    fchange(data) {
      this.isLoadPhoto = false;
      this.photos = []
      this.selectedItemRPhotos = data
    },

    dmpfchange(data) {
      if(this.selectedItemReport.photo?.filter(x => x.dmp)[0]) {
        this.removeItemImage(this.selectedItemReport.photo.filter(x => x.dmp)[0])
      }
      this.isLoadDMPPhoto = false;
      this.dmpPhotos = []
      this.selectedItemDMPPhotos = data
    },

    validateDmp(field){
      let rules = []
      this.selectedItemReport.reportFieldWithData.map(f => {
        if(f.name == "ДМП" && f.value == 'Да'){
          rules = [
            v => v != '-' || `Обязательное поле`
          ]
        }
      })
      return rules 
    },

    validateField(f) {
      let rules = [
          v => !!v || `Обязательное поле`
      ]

      if (f.fieldType.name === "INT") rules.push(v => /^[0-9]{1,}/.test(v) || `Поле должно содержать только целые числа`)
      if (f.fieldType.name === "FLOAT") rules.push(v => /^[0-9]+((,|\.)[0-9]+)?$/.test(v) || `Поле должно содержать только числа`)
      // if (this.currentproduct.hasAction && (f.fieldType.name === "INT" || f.fieldType.name === "FLOAT")) {
      //     rules.push(v => Number(v) > 0 || `Обязательное поле`);
      // }

      return rules
    },

    submit() {
      if (!this.selectedItemReport.photo){
        this.selectedItemReport.photo = []
      }
      if (!this.isLoadPhoto && this.$refs.form.validate()) {
        if(this.selectedItemReport.reportFieldWithData.filter(field => field.name === 'ДМП' && field.value === 'Да').length > 0 && (this.selectedItemDMPPhotos.length == 0 && this.selectedItemReport.photo?.filter(x => x.dmp).length == 0 )){
          this.openSnackbar('Не заполнены фото ДМП', 'error');
          return;
        }
        // if (this.selectedItemReport.reportFieldWithData.filter(field => field.name === 'ДМП' && field.value === 'Да').length > 0
        //     && this.selectedItemReport.photo.filter(x => x.dmp).concat(this.selectedItemDMPPhotos).length === 0) {
        //         this.openSnackbar('Не заполнены фото ДМП', 'error');
        //         return;
        //     }
        this.loadingData = true;
        let data = {
            projectId: this.id,
            tradePointId: this.pid,
            productId: this.selectedItem.id,
            reportItemInfo: this.selectedItemReport,
            photos:this.selectedItemRPhotos.concat(this.selectedItemDMPPhotos),
            comment: this.selectedItemReport.comment
        }
        if (this.selectedItem.reportData != null)
          data['presense'] = this.selectedItem.reportData.presence
          reportService.sendReportData(data)
            .then(data => {
                this.loadingData = false;
                // this.selectedItem.reportData = data
                
                if (!this.report) this.loadReport()
                this.loadProducts()
                this.countActions()
                this.dialog = false
            })
            .catch(error => {
                this.loadingData = false;
                this.productLoading = false
                this.productError = error
            })
        this.selectedItem = null
        this.selectedItemReport = {}
        this.selectedItemRPhotos = []
        this.selectedItemDMPPhotos = []
        this.selectedItem = null
        this.productLoading = false
        this.productError = null
        }
      },
    openSnackbar(text, type) {
        this.snackbar.text = text
        this.snackbar.type = type
        this.snackbar.open = true
    },
  }
}
</script>

<style>
    .dialog-barcode{
      width: auto;
    }
    .barcode{
      display: flex;
      justify-content: center;
    }
    .emptyspace {height:100px;}
    .photos {height:100px;}
    #repfileslable {
            width: 56px;
            height: 56px;
            display: inline-flex;
            cursor: pointer;
            background: #ff4081;
            border-radius: 50%;
            text-align: center;
            align-items: center;
            justify-content: center;
    }
    .v-input input {
        text-align: left !important;
    }
    #repfileslable + .v-file-input {display:none;}
    #likecb {
        width:30px;
        min-width:30px;
        height:30px;}
</style>