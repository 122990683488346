<template lang="pug">
  v-text-field(:value="value" type="number" :label="label" :rules="rules" @change="change" @keypress="isNumber($event)")
</template>

<script>
export default {
  props: ['value', 'label', 'rules'],
  data: function() {
    return {}
  },
  methods: {
    change(val) {
      this.$emit('input', val)
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
}
</script>